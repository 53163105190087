<template lang="pug">
  .terms.content.mainContainer
    h2 利用規約
    p これから以下に示すこれらの利用規約(利用契約・サービス契約)は、我々（以下、FORGETMEN）が運営するforgetman.app(以下、forgetman)とあなた(以下、ユーザー様)との関係を規定します。ユーザー様は以下に示す文書に法的に拘束されるので、ユーザー様がこれらを読むことは重要です。ユーザー様による forgetman へのアクセス及び使用は、本利用規約へのユーザー様の同意および遵守に基づいています。本利用規約は、forgetman にアクセスまたは使用するすべての訪問者、ユーザー、顧客、およびその他の者に適用されます。forgetman にアクセスまたは使用することにより、ユーザー様は利用規約に拘束され、すべての法的結果を受け入れることに同意するものとします。ユーザー様が利用契約の諸条件の全部または一部に同意しない場合は、本サービスを使用しないでください。

    h3 サービス内容
    p forgetman が提供するサービスとは、以下のものを全て示します
    ul
      li 安全な文書保管
      li あなたのブラウザの LocalStorage 上での文書管理及びそれに関連するシステムおよび技術
      li 本サービス forgetman.app
      li forgetman.app 上で提供しているすべてのソフトウェア、アプリケーション、forgetman 側で定義したデータ、テキスト、画像、機能

    p また、forgetman に対するいかなる修正も本規約の対象となります。我々、FORGETMEN は、予告なしに forgetman またはその機能をいつでも変更または中止する権利を留保します。forgetman に対するすべての権利、権原および利益は、FORGETMEN に帰属します。

    h3 アカウント・登録文書データについて
    p forgetman は、登録不要のサービスです。登録は現時点では不要ですが、保存した文書は使用したブラウザのみで閲覧が可能です。登録した文書の一部に非表示機能を設けていますので、ショルダーハック等を心配される場合はご利用ください。また、現時点ではバックアップ機能も設けていません。ブラウザからデータを消した場合、二度と復元はできません。 この点について、FORGETMEN は一切の責務を負わないとします。

    h3 知的財産
    p forgetman上のテキスト、画像、グラフィック、コードを含むすべてのコンテンツ（ただし、ユーザー様が入力したデータと保存されたそのデータを除く）は FORGETMEN の所有物であり、著作権、商標、およびその他の知的所有権によって保護されています。ユーザー様は、ユーザー様自身の非営利目的での使用のためにのみ、本サービスの異なる領域からの資料の一部を表示し、コピー、ダウンロードまたは印刷することができます。その他の使用は固く禁じられており、著作権、商標およびその他の法律に違反する可能性があります。本利用規約は、FORGETMEN の商標を使用するためのライセンスをユーザー様に許諾するものではありません。ユーザー様はさらに、本サービスからダウンロードした資料からの所有権表示を使用、変更、削除しないことに同意します。

    h3 ユーザー作成文書データ
    p ユーザー様の作成した「ユーザー作成文書データ」とは、ユーザー様が本サービスを通じて、保存、目視やコピーアンドペーストによる取得・登録、またはその他の方法で提供するデータおよびコンテンツを意味します。ユーザー様はユーザー様が作成した文書データに対するすべての権利を保有します。ユーザー作成文書データは、FORGETMEN では取得・閲覧・またはその利用をすることは一切ございません。ユーザー様による forgetman の利用はこの事実に基づいて同意するものとし、いかなる場合においても FORGETMEN は責務を負わないこととします。

    h3 広告などによる他のWebサイトへのリンク
    p forgetman には、FORGETMEN が所有または管理していない第三者のWebサイトまたはサービスへのリンクが含まれている場合があります。FORGETMEN は、第三者のWebサイトまたはサービスのコンテンツ、プライバシーポリシー、または慣行を管理することはなく、それらに対する責任も負いません。forgetman で表示された他のWebサイトへの広告やリンクを出力するにあたり、以下の ※1 について直接的または間接的に責任を負わないものとします。 
    p ※1 forgetman で表示している広告商品、広告リンク先への遷移、またはサービスの使用によって引き起こされた、またはそれらに依存して引き起こされた、またはそれらに起因すると思われるあなたが受けたいかなる損害

    h3 契約の終了・利用規約違反について
    p ユーザー様による 本サービスを提供行うにあたり必要なインフラやまたはそれらに対し、故意による負荷・攻撃と判断した場合、アクセス禁止処置をはかることがあります。また、本利用規約違反と認められた場合、アクセス禁止の上法的処置を取ることもあります。

    h3 責任
    p forgetman を利用の上で保存されたデータが、なんらかしらの方法で盗まれたとしても、FORGETMEN はユーザー様が受けた間接的、偶発的、特別、派生的または懲罰的な損害に責任を負いません。また、ユーザー様の利用環境の破損、データを保存しているソフトウェアの更新、仕様変更などに即したデータの破損・損失についても FORGETMEN はユーザー様が受けた間接的、偶発的、特別、派生的または懲罰的な損害に責任を負いません。

    h3 免責事項
    p 繰り返しになりますが、forgetman の利用により生じた損失や損害などについては、いかなる場合も一切責任を負うものではありません。

    h3 プライバシーポリシー
    p forgetman が直接、ユーザー様の個人情報を取り扱うことがありませんが、定義だけ行います。
    
    h3 個人情報とは
    p 個人情報は「個人を識別・特定できるモノ」であり forgetman 上から直接扱うことがありません。

    h3 個人情報が収集されるシーン
    p 3rd Party Script によって埋め込まれたお客様のブラウザ内の情報と、そのスクリプトによるもの。Google adsense や ソーシャルサービスへのシェア（facebook、twitter）など。

    h3 情報の第三者への開示
    p 法令・国の機関による開示命令、人命・身体・財産の保護、サービス存続の危機にともない収集が必要と forgetmen が判断した場合

    h3 プライバシーポリシーの変更
    p サービスの進歩、変化に伴いこのプライバシーポリシーは変更される可能性があります。ユーザー様はサービスを使うことでこれに同意したものとします。
    
</template>

<script>
export default {
  name: 'terms',
}
</script>
<style scoped lang="stylus">
.terms
  width: 80%
  margin: 0 auto
  padding: 20px 0px 80px 0px
  h2
    font-size: 1.5rem
    font-weight: bolder
    line-height: 3em
    letter-spacing: 0.2em
    text-align: center
  h3
    font-weight: bolder
    font-size: 1.1rem
    line-height: 2em
    margin-top: 20px
  p, li
    font-size: 0.9rem
    line-height: 2em
    color: #666
    letter-spacing: 0.1em
</style>